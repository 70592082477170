.badge {
    border-radius: 10px;
    color: #fff;
    line-height: 12px;
    font-size: 12px;
    padding: 2px 8px;
    padding-bottom: 4px;
    font-weight: 400;

    &.badge-default {
        background-color: #fff;
        color: #333;
    }

    &.badge-dark {
        background-color: darken(@brand-default, 8%);
    }

    &.badge-primary {
        background-color: @brand-primary;
    }

    &.badge-success {
        background-color: @brand-success;
    }

    &.badge-info {
        background-color: @brand-info;
    }

    &.badge-warning {
        background-color: @brand-warning;
    }

    &.badge-danger {
        background-color: @brand-danger;
    }

    &.badge-purple {
        background-color: @brand-purple;
    }

    &.badge-pink {
        background-color: @brand-pink;
    }

    &.badge-faded-blue {
        background-color: @brand-faded-blue;
    }
}