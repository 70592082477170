body {
	background-color: @default-skin-sidebar-bg;
	color: @default-skin-text-color;
	font-family: @primary-font;
}

a {
    .main-transition();
}

::-moz-selection {
    background: fade(@brand-primary, 45%);
}
::selection {
    background: fade(@brand-primary, 45%);
}