.show-grid {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    [class^=col-] {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #eee;
        background-color: fade(@brand-primary, 25%);
        border: 1px solid #ddd;
        border: 1px solid fade(@brand-primary, 35%);
    }
}