pre {
    color: #f3f3f3;
    background-color: #444b4e;
    border: 1px solid #2f3335;
}

code {
    color: #fd9999;
    background-color: #353535;
}

blockquote {
    border-left: 3px solid rgba(255,255,255,0.2);
    footer, small, .small {
        color: rgba(255,255,255,0.35);
    }
}
blockquote.blockquote-reverse {
    border-right: 3px solid rgba(255,255,255,0.2);
}

mark, .mark {
    background-color: rgb(230, 194, 58);
    color: #333;
}

.light {
    font-weight: 300;
}