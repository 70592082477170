.note-editor.note-frame.panel.panel-default {
    border: 1px solid #292f31;

    .note-editing-area .note-editable {
        background-color: rgba(255,255,255,0.1);
        border: 0;
        color: #fff;
        height: 180px;
    }

    .note-statusbar {
        background-color: #42494c;
        .note-resizebar .note-icon-bar {
            width: 20px;
            margin: 1px auto;
            border-top: 1px solid rgba(255,255,255,0.3);
        }
    }
    .modal {
        position: fixed;
        top: 110px;
    }
}
