.form-control {
    color: #FDFDFD;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 0;
    padding: 5px 12px;    
    .placeholder(rgba(255,255,255,0.45));
    &:focus {
        border: 1px solid rgba(255,255,255,0.65);
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(255,255,255,.075),0 0 10px rgba(255, 255, 255, 0.15);
        box-shadow: inset 0 1px 1px rgba(255,255,255,.075),0 0 10px rgba(255, 255, 255, 0.15);
    }
}
select.form-control {
    option {
        color: #000;
    }
}
.form-horizontal .form-group {
    margin-right: -10px;
    margin-left: -10px;
}

.form-inline .form-group, .form-inline .checkbox {
    margin-right: 10px;
    label {
        margin-right: 5px;
    }
}
.icheck-label {
    margin-left: 3px;
    font-size: 16px;
    top: 1px;
    position: relative;
    font-weight: normal;
    cursor: pointer;
}
.form-group {
    &.form-group-lg, &.form-group-sm {
        .form-control {
            border-radius: 0;
        }
    }
}

.help-block {
    color: rgba(255,255,255,0.3);
}

.has-error {
    .form-control-validation(lighten(@brand-danger, 10%), @brand-danger, @brand-danger);
}

.has-warning {
    .form-control-validation(lighten(@brand-warning, 10%), @brand-warning, @brand-warning);
}

.has-success {
    .form-control-validation(lighten(@brand-success, 10%), @brand-success, @brand-success);
}