.profile-details {
    .profile-details-profile-picture {
        border-radius: 50%;
        width: 130px;
        height: 130px;
        overflow: hidden;
        float: left;
        margin-right: 30px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .profile-details-info {
        float: left;
        .profile-details-info-name {
            margin: 0;
            font-size: 21px;
        }
        .profile-details-info-summary {
            color: #ccc;
            margin: 10px 0;
        }
        .profile-details-info-contact-list {
            padding: 0;
            list-style: none;
            .profile-details-info-contact-list-item {
                font-size: 14px;
                margin-bottom: 4px;
                .profile-details-info-contact-list-item-icon {
                    width: 22px;
                }
                color: #eee;
                a {
                    color: #eee;
                    border-bottom: 1px solid rgba(255,255,255,0);
                    &:hover {
                        border-bottom: 1px solid rgba(255,255,255,0.3);
                    }
                }
            }
        }
    }
}

.profile-resource-list {
    list-style: none;
    padding: 0;
    .profile-resource-list-item {
        .clearfix;
        border: 1px solid rgba(255,255,255,0.2);
        padding: 6px 10px;
        margin-bottom: 10px;
        font-size: 16px;
        .profile-resource-list-item-icon {
            width: 16px;
            margin-right: 0;
        }
        .btn:last-child {
            margin-right: 5px;
        }
    }
}


@media (max-width: 1200px) {
    .profile-details {
        text-align: center;
        .profile-details-profile-picture {
            float: none;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .profile-details-info {
            width: 100%;
            float: none;
        }
    }
}