.modal-content {
    background: @brand-default;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}

.modal-header {
    border-bottom: 1px solid rgba(255,255,255,0.2);
}

.modal-footer {
    border-top: 1px solid rgba(255,255,255,0.2);
}