.social-conversation {
    .social-conversation-item {
        text-align: left;
        padding: 10px 0;
        border-bottom: 1px solid rgba(255,255,255,0.2);
        .clearfix();

        &:last-child {
            border-bottom: 0;
        }
        &.social-conversation-item-right {
            text-align: right;
            .social-conversation-item-ago {
                float: left;
                margin-right: 10px;
                margin-left: 0;
                text-align: left;
            }

            .social-conversation-item-message {
                font-size: 13px;
                margin-right: 45px;
                margin-left: 110px;
            }

            .social-conversation-item-image {
                float: right;
                margin-right: 0;
                margin-left: 10px;
            }
        }

        .social-conversation-item-image {
            float: left;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 10px;
            overflow: hidden;
        }

        .social-conversation-item-message {
            display: block;
            font-size: 13px;
            margin-left: 45px;
            margin-right: 110px;
            .social-conversation-item-message-from {
                font-weight: bold;
            }
            .social-conversation-item-message-content {
                
            }
            .social-conversation-item-message-timestamp {
                margin-top: 7px;
                color: rgba(255,255,255, 0.7);
                font-size: 11px;
            }
        }
        .social-conversation-item-ago {
            float: right;
            text-align: right;
            color: rgba(255,255,255, 0.7);
            font-size: 11px;
            width: 100px;
            margin-left: 10px;
        }
    }

}