.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #fff;
    cursor: default;
    background-color: rgba(255,255,255, 0.1);
    border: 1px solid rgba(255,255,255, 0.2);
    border-bottom-color: transparent;
}
.nav-tabs {
    border-bottom: 1px solid rgba(255,255,255, 0.2);
}

.nav-tabs>li>a:hover {
    background-color: rgba(255,255,255, 0.1);
    border: 1px solid rgba(255,255,255, 0.2);
}