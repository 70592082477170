.progress {
    .progress-bar-bg-dark();
    height: 12px;
    border-radius: 0;
    margin: 6px 0;
    > .progress-bar {
        font-size: 12px;
        line-height: 11px;
    }
}
.progress-bar-bg-light {
    background: #f5f5f5;
}

.progress-bar-bg-dark {
    background: rgba(0,0,0,0.15);
}

.progress-bar-defaulty, .progress-bar {
    .progress-bar-variant(#202324);
}

.progress-bar-light {
    .progress-bar-variant(#ccc);
}

.progress-bar-primary {
    .progress-bar-variant(darken(@brand-primary, 5%));
}

.progress-bar-success {
    .progress-bar-variant(darken(@brand-success, 5%));
}

.progress-bar-info {
    .progress-bar-variant(darken(@brand-info, 5%));
}

.progress-bar-warning {
    .progress-bar-variant(darken(@brand-warning, 5%));
}

.progress-bar-danger {
    .progress-bar-variant(darken(@brand-danger, 5%));
}

.progress-bar-pink {
    .progress-bar-variant(darken(@brand-pink, 5%));
}

.progress-bar-faded-blue {
    .progress-bar-variant(darken(@brand-faded-blue, 5%));
}

.progress-bar-purple {
    .progress-bar-variant(darken(@brand-purple, 5%));
}