.faqs-question {
    .faqs-question-text {
        cursor: pointer;
        font-size: 24px;
        padding-right: 11px;
        padding-bottom: 16px;
        margin: 0;
        margin-bottom: 12px;
        margin-top: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        font-weight: 400;
        .faqs-question-toggle-icon-closed {
            margin-top: 3px;
            margin-right: 3px;
            float: right;
            color: rgba(255, 255, 255, 0.5);
        }
        .faqs-question-toggle-icon-open {
            margin-top: 3px;
            margin-right: 3px;
            float: right;
            color: rgba(255, 255, 255, 0.5);
            display: none;
        }
    }

    &.open {
        .faqs-question-text {
            .faqs-question-toggle-icon-closed {
                display: none;
            }
            .faqs-question-toggle-icon-open {   
                display: block;
            }
    }
    }

    .faqs-question-answer {
        cursor: pointer;
        font-size: 15px;
        padding-bottom: 18px;
        padding-top: 10px;
        color: #DDD;
        p {
            margin-top: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}