.dropdown-menu-messages {
    padding: 10px;
    li:last-child  .dropdown-menu-messages-item {
        border-bottom: 0;
        padding-bottom: 3px;
    }
    li:first-child  .dropdown-menu-messages-item {
        padding-top: 3px;
    }
    .dropdown-menu-messages-item {
        padding: 9px 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        .clearfix();
        .dropdown-menu-messages-item-image {
            float: left;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 10px;
            margin-top: 6px;
            overflow: hidden;
            img {
                width: 35px;
                height: auto;
            }
        }

        .dropdown-menu-messages-item-content {
            display: block;
            font-size: 13px;
            margin-left: 45px;
            margin-right: 110px;
            .dropdown-menu-messages-item-content-from {
                font-weight: bold;
            }
            .dropdown-menu-messages-item-content-snippet {
                
            }
            .dropdown-menu-messages-item-content-timestamp {
                margin-top: 3px;
                color: rgba(255,255,255, 0.7);
                font-size: 11px;
            }
        }
        .dropdown-menu-messages-item-ago {
            float: right;
            text-align: right;
            color: rgba(255,255,255, 0.7);
            font-size: 11px;
            width: 100px;
            margin-left: 10px;
        }
    }
}
// .navbar-default .navbar-nav .open .dropdown-menu>li>a {
//     color: #fff;
//     &:hover {
//         color: #fff;
//     }
// }
.dropdown-menu-messages, .dropdown-menu-notifications, .navbar-default .navbar-nav .open .dropdown-menu {
    padding: 10px;
    width: 300px;
    background-color: @default-skin-navbar-bg;
    border: 1px solid lighten(@default-skin-navbar-bg, 15%);
    // border-top: 0;
    border-radius: 0;
    > li {
        float: none;
        > a {
            color: #fff;
            width: 100%;
            &:hover, &:active, &:focus {
                background-color: lighten(@default-skin-navbar-bg, 10%);
                color: #fff;
            }
        }
    }
    .dropdown-menu-link-lg {
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: center;
        font-size: 16px;
    }
    .dropdown-menu-link-md {
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 14px;
        text-align: center;
    }
    .dropdown-menu-link-sm {
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 12px;
        text-align: center;
    }
}

.dropdown-menu-notifications {
    padding: 10px;
    li:last-child  .dropdown-menu-notifications-item {
        border-bottom: 0;
    }
    .dropdown-menu-notifications-link {
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: center;
    }
    .dropdown-menu-notifications-item {
        padding: 12px 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        .clearfix();
        .dropdown-menu-notifications-item-content {
            font-size: 12px;
            float: left;
        }
        .dropdown-menu-notifications-item-ago {
            float: right;
            text-align: right;
            margin-top: 4px;
            color: rgba(255,255,255, 0.7);
            font-size: 11px;
        }
    }
}
.dropdown-menu {
    background-color: @default-skin-navbar-bg;
    border-color: lighten(@default-skin-navbar-bg, 20%);
    > li > a {
        color: #fff;
        &:hover, &:focus, &:active {
            background-color: lighten(@default-skin-navbar-bg, 10%);
            color: #fff;
        }
    }

    border-radius: 2px;

    &.dropdown-menu-notifications, &.dropdown-menu-messages {
        padding: 10px;
        width: 300px;
    }

    .divider {
        background-color: lighten(@default-skin-navbar-bg, 20%);
    }
}

.navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu>li>a {
    padding: 12px;
}