.log-in-wrapper {
    width: 300px;
    margin: 12% auto;
}

.form-group {
    margin-bottom: 25px;
}

.log-in-title {
    font-size: 24px;
    margin-bottom: 20px;
    .log-in-icon {
        float: left;
        font-size: 45px;
        width: 42px;
        margin-right: 6px;
    }
}

.login-checkbox {
    label {
        font-weight: normal;
        font-size: 17px;
        margin-left: 5px;
    }
    input {
        position: relative;
        top: 1px;
    }
    margin-bottom: 25px;
}

.login-bottom-links {
    .clearfix();
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
    li {
        margin: 8px 0;
        a {
            color: #fff;
            opacity: 0.5;
            display: block;
            &:hover {
                opacity: 1;
            }
        }
    }
}